/*
 * 生产管理相关接口列表
 */

import axios from '@/utils/http'; // 导入http中创建的axios实例
//计划列表接口
export const selectPlanByPagination = (param) => {
  return axios.post(`/personnelapi/knCreatePlan/selectPlanByPagination`, param);
}
//计划创建
export const insertCreatePlan = (param) => {
  return axios.post(`/personnelapi/knCreatePlan/insertCreatePlan`, param);
}

//计划详情
export const selectCreatePlanById = (param) => {
  return axios.get(`/personnelapi/knCreatePlan/selectCreatePlanById?id=${param}`);
}
//计划审批
export const updateToPlanApproval = (param) => {
  return axios.post(`/personnelapi/knCreatePlan/updateToPlanApproval`, param);
}
//计划修改
export const updateCreatePlan = (param) => {
  return axios.post(`/personnelapi/knCreatePlan/updateCreatePlan`, param);
}
//计划终止
export const updateToEndPlan = (param) => {
  return axios.post(`/personnelapi/knCreatePlan/updateToEndPlan`, param);
}
//计划负责人修改
export const updatePlanNode = (param) => {
  return axios.post(`/personnelapi/knCreatePlan/updatePlanNode`, param);
}
//计划历史
export const selectHistoryPlanInfo = (param) => {
  return axios.post(`/personnelapi/knCreatePlan/selectHistoryPlanInfo`, param);
}

//进度总览
export const selecProgressList = (param) => {
  return axios.post(`/personnelapi/knProgress/selectProgressList`, param);
}
//进度列表
export const selectPlanInfoList = (param) => {
  return axios.post(`/personnelapi/knProgress/selectPlanInfoList`, param);
}
//详情进度
export const selectPlanInfoById = (param) => {
  return axios.post(`/personnelapi/knProgress/selectPlanInfoById`, param);
}